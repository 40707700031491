import Card from "react-bootstrap/Card";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEdit,
  faEnvelope,
  faPhone,
  faTrashAlt,
  faUser,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import "./Users.css";
import { urls } from "../../Config/Consts";
import { postRequest } from "../../Config/SharedFunctions";
import { useEffect } from "react";
import { Fade, ListGroup } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useNavigate } from "react-router-dom";
import { baseURL, basefileURL } from "../../Config/Consts";
function Users() {
  const [usersList, setUsersList] = useState([]);
  const nav = useNavigate();
  const userSession = JSON.parse(sessionStorage.getItem("user"));

  const getUsersList = async (location = null) => {
    const data = { location: location };
    let res = await postRequest(urls?.user?.getUsers, data);
    if (res?.data?.response == true) {
      setUsersList(res?.data?.message);
    } else {
      console.log(res?.data?.message);
    }
  };

  const deleteUserRecord = async (id = null) => {
    const data = { id: id };
    let res = await postRequest(urls?.user?.deleteUser, data);
    if (res?.data?.response == true) {
      if (userSession.role == "Team Lead") {
        getUsersList(userSession.districts);
      } else {
        getUsersList();
      }
    } else {
      console.log(res?.data?.message);
    }
  };

  useEffect(() => {
    if (userSession.role == "Team Lead") {
      getUsersList(userSession.districts);
    } else {
      getUsersList();
    }
  }, []);

  function editUser(id) {
    nav("/create-uesr/"+id);
  }

  function deleteUser(id, name) {
    let text = "Are you sure! you want delete user " + name + " ?";
    confirmAlert({
      title: "Confirm to delete",
      message: text,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteUserRecord(id);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  return (
    <Card className="mt-2">
      <Card.Header className="primary-colors">
        <FontAwesomeIcon icon={faUserGroup} /> All Users
      </Card.Header>
      <Card.Body>
        <ListGroup variant="flush">
          {usersList.map((e, i) => (
            <ListGroup.Item>
              <div className="row">
                <div className="col-md-12">
                  <FontAwesomeIcon icon={faUser} /> {e.name} &nbsp; | &nbsp;{" "}
                  <FontAwesomeIcon icon={faEnvelope} /> {e.email} &nbsp; |
                  &nbsp; <FontAwesomeIcon icon={faPhone} /> {e.mobile} &nbsp; |
                  &nbsp; <strong>Designation: </strong>
                  {e.designation}
                 
                </div>
                <div className="col-md-12 mt-1">
                <strong>Location: </strong>  {e.location} &nbsp; | &nbsp; <strong>Project: </strong> {e.projects} &nbsp; | &nbsp; 
                  {e?.rc_doc != "" ? <a target="NEW" href={basefileURL+e?.rc_doc}><FontAwesomeIcon icon={faDownload} /> RC</a> :""}
                  &nbsp; &nbsp; {e?.license_doc != "" ? <a target="NEW" href={basefileURL+e?.license_doc}><FontAwesomeIcon icon={faDownload} /> License</a> :""}
                  &nbsp; &nbsp; {e?.insurance_doc != "" ? <a target="NEW" href={basefileURL+e?.insurance_doc}><FontAwesomeIcon icon={faDownload} /> Insurance</a> :""}

                  <span className="float-end">
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => editUser(e.user_id)}
                    />{" "}
                    &nbsp;{" "}
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      onClick={() => deleteUser(e.user_id, e.name)}
                    />
                  </span>
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

export default Users;
