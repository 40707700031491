import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { urls } from "../../Config/Consts";
import { getData, postRequest } from "../../Config/SharedFunctions";

import {
  faCheck,
  faDownload,
  faEdit,
  faListCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import EasyEdit, { Types } from "react-easy-edit";
import "./Profile.css";
import { Button, InputGroup, Modal } from "react-bootstrap";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { basefileURL } from "../../Config/Consts";
function Profile() {
  const saveAddress = (value) => {
    updateProfile("address", value);
  };
  const saveDataOfBirth = (value) => {
    updateProfile("dob", value);
  };
  const saveGender = (value) => {
    updateProfile("gender", value);
  };
  const saveInsuranceNo = (value) => {
    updateProfile("insurance_no", value);
  };

  const saveLicenseNo = (value) => {
    updateProfile("license_no", value);
  };

  const saveRCNo = (value) => {
    updateProfile("rc_no", value);
  };

  const saveModeOfTravel = (value) => {
    updateProfile("mode_of_travel", value);
  };

  const cancel = () => {
    console.log("Cancelled request");
  };
  const [insuranceProof, setInsuranceProof] = useState('');
  const [rcProof, setRCProof] = useState('');
  const [licenseProof, setLicenseProof] = useState('');
  const onRCProofFileChange = (e) => {
    if (e.target.files) {
      setRCProof(e.target.files[0]);
    }
  };
  const onRCProofFileUpload = () => {    
    const formData = new FormData();
    formData.append("rcproof", rcProof);
    uploadFile(formData);
  };

  const onLicenseProofFileChange = (e) => {
    if (e.target.files) {
      setLicenseProof(e.target.files[0]);
    }
  };
  const onLicenseProofFileUpload = () => {    
    const formData = new FormData();
    formData.append("licenseproof", licenseProof);
    uploadFile(formData);
  };

  const onInsuranceProofFileChange = (e) => {
    if (e.target.files) {
      setInsuranceProof(e.target.files[0]);
    }
  };
  const onInsuranceProofFileUpload = () => {    
    const formData = new FormData();
    formData.append("insuranceproof", insuranceProof);
    uploadFile(formData);
  };
  const uploadFile = async (formData) => {
    const loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    formData.append("email", loggedInUser.email);
    formData.append("mobile", loggedInUser.mobile);
    let res = await postRequest(urls?.user?.uploadprofile, formData);
    if (res?.data?.response == true) {
      alert(res?.data?.message);
    } else {
      alert(res?.data?.message);
    }
  };

  const updateProfile = async (inputkey, inputvalue) => {
    const loggedInUser = JSON.parse(sessionStorage.getItem("user"));

    const user = {
      keyfield: inputkey,
      datavalue: inputvalue,
      email: loggedInUser.email,
    };
    let res = await postRequest(urls?.user?.uprofile, user);
    if (res?.data?.response == true) {
      console.log(res?.data?.message);
    } else {
      console.log(res?.data?.message);
    }
  };
  const [validated, setValidated] = useState(false);
  const [show, setUpdateProfileRequestShow] = useState(false);
  const handleUpdateProfileRequestClose = () =>
    setUpdateProfileRequestShow(false);
  const handleUpdateProfileRequestShow = () =>
    setUpdateProfileRequestShow(true);
  const [requestType, setRequestType] = useState("");
  const [requestName, setRequestName] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [userProfile, setUserProfile] = useState("");
  const userSession = JSON.parse(sessionStorage.getItem("user"));

  const editorContent = draftToHtml(
    convertToRaw(editorState.getCurrentContent())
  );
  const handleUpdateProfileRequestSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      const update_profile = {
        requestType,
        requestName,
        editorContent,
      };
    }
  };
  const getUserProfile = async () => {
    let res = await postRequest(urls?.user?.profile, userSession);
    if (res?.data?.response == true) {
      setUserProfile(res?.data?.message);
    } else {
      console.log(res?.data?.message);
    }
  };
  useEffect(() => {
    getUserProfile();
  }, []);

  console.log(userProfile);
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleUpdateProfileRequestClose}
        backdrop="static"
        keyboard={false}
      >
        <Form
          className="update_profile_request_form"
          noValidate
          validated={validated}
          onSubmit={handleUpdateProfileRequestSubmit}
        >
          <Modal.Header closeButton>
            <Modal.Title>Request for update profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Request Type</Form.Label>
              <InputGroup hasValidation>
                <Form.Select
                  className="rounded-0"
                  value={requestType}
                  required
                  aria-label="Request Type"
                  onChange={(e) => setRequestType(e.target.value)}
                >
                  <option value="">Select Request Type</option>
                  <option value="Personal Information">
                    Personal Information
                  </option>
                  <option value="Documents">Documents</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Request Type is a required field
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Request Name/Subject</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  size="lg"
                  type="text"
                  required
                  id="request_name"
                  name="request_name"
                  className="input-heigt rounded-0"
                  placeholder="Ex: Mobile number change request"
                  onChange={({ target }) => setRequestName(target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Request Name/Subject is a required field
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              {/* <InputGroup hasValidation>
                <Form.Control as="textarea" rows={3} />
                <Form.Control.Feedback type="invalid">
                  Description is a required field
                </Form.Control.Feedback>
              </InputGroup> */}
              {/* <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
              /> */}
              <Editor
                editorState={editorState}
                wrapperClassName="txtedit-wrapper"
                editorClassName="txtedit-editor"
                toolbarClassName="txtedit-toolbar"
                onEditorStateChange={setEditorState}
              />
              <textarea
                className="update-editor-contet"
                value={editorContent}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" className="light-border primary-colors">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Card>
        <Card.Header className="primary-colors">
          <FontAwesomeIcon icon={faListCheck} /> Profile
          {/* <Button
            className="update-profile float-end px-2"
            onClick={handleUpdateProfileRequestShow}
          >
            <FontAwesomeIcon icon={faEdit} /> Update Profile Request
          </Button> */}
        </Card.Header>
        <Card.Body className="p-1 profile">
          <Row>
            <Col lg={5}>
              <Table hover className="info-table table-responsive">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{userProfile?.name}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{userProfile?.email}</td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td>{userProfile?.mobile}</td>
                  </tr>
                  <tr>
                    <td>Role</td>
                    <td>{userProfile?.role}</td>
                  </tr>
                  <tr>
                    <td>Designation</td>
                    <td>{userProfile?.designation}</td>
                  </tr>
                  <tr>
                    <td>Location</td>
                    <td>{userProfile?.location}</td>
                  </tr>
                  <tr>
                    <td>Date of Birth</td>
                    <td>
                      <EasyEdit
                        type={Types.DATE}
                        onSave={saveDataOfBirth}
                        onCancel={cancel}
                        value={userProfile?.dob}
                        instructions={"Click to select"}
                        editMode={userProfile?.dob != "" ? false : true}
                        saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                        cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                        attributes={{
                          name: "dob",
                          className: "rounded-0 form-control form-control",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td>
                      <EasyEdit
                        type={Types.RADIO}
                        value={userProfile?.gender}
                        editMode={userProfile?.gender != "" ? false : true}
                        onSave={saveGender}
                        onCancel={cancel}
                        options={[
                          { label: " Male", value: "Male" },
                          { label: " Female", value: "Female" },
                          { label: " Other", value: "Other" },
                        ]}
                        saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                        cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                        attributes={{
                          name: "gender",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>
                      <Form.Label>{userProfile?.address}</Form.Label>
                      <EasyEdit
                        type={Types.TEXTAREA}
                        onSave={saveAddress}
                        onCancel={cancel}
                        instructions={"Click here to Edit"}
                        //value={userProfile?.address}
                        editMode={userProfile?.address != "" ? false : true}
                        saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                        cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                        attributes={{
                          rows: "3",
                          cols: "40",
                          name: "permanent-address",
                          className: "rounded-0 form-control",
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col lg={7}>
              <Table hover className="info-table table-responsive">
                <tbody>
                  <tr>
                    <td>Mode of travel <span className="mand">*</span></td>
                    <td>
                      <EasyEdit
                        type={Types.SELECT}
                        options={[
                          { label: "Two wheeler", value: "Two wheeler" },
                          { label: "Four wheeler", value: "Four wheeler" },
                        ]}
                        onSave={saveModeOfTravel}
                        value={userProfile?.mode_of_travel}
                        saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                        cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                        attributes={{
                          name: "mode_of_travel",
                          className: "rounded-0 form-control form-control",
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>RC No. <span className="mand">*</span></td>
                    <td>
                      <EasyEdit
                        type={Types.TEXT}
                        onSave={saveRCNo}
                        onCancel={cancel}
                        value={userProfile?.rc_no}
                        editMode={userProfile?.rc_no != "" ? false : true}
                        saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                        cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                        attributes={{
                          name: "rc-number",
                          className:
                            "text-uppercase rounded-0 form-control form-control",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Upload proof <span className="mand">*</span></td>
                    <td>
                    <input
                        type="file"
                        onChange={onRCProofFileChange}
                      />
                      <button onClick={onRCProofFileUpload}>
                        Upload!
                      </button>
                      <p className="mt-2"><a download href={basefileURL+userProfile?.rc_doc}>{userProfile?.rc_doc?.split(/[/]+/).pop()}</a></p>
                    </td>
                  </tr>

                  <tr>
                    <td>License No <span className="mand">*</span></td>
                    <td>
                      <EasyEdit
                        type={Types.TEXT}
                        onSave={saveLicenseNo}
                        onCancel={cancel}
                        value={userProfile?.license_no}
                        editMode={userProfile?.license_no != "" ? false : true}
                        saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                        cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                        attributes={{
                          name: "license_no",
                          className:
                            "text-uppercase rounded-0 form-control form-control",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Upload proof <span className="mand">*</span></td>
                    <td>
                      <input
                        type="file"
                        onChange={onLicenseProofFileChange}
                      />
                      <button onClick={onLicenseProofFileUpload}>
                        Upload!
                      </button>                      
                      <p className="mt-2"><a download href={basefileURL+userProfile?.license_doc}>{userProfile?.license_doc?.split(/[/]+/).pop()}</a></p>
                    </td>
                  </tr>

                  <tr>
                    <td>Insurance No <span className="mand">*</span></td>
                    <td>
                      <EasyEdit
                        type={Types.TEXT}
                        onSave={saveInsuranceNo}
                        onCancel={cancel}
                        value={userProfile?.insurance_no}
                        editMode={
                          userProfile?.insurance_no != "" ? false : true
                        }
                        saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                        cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                        attributes={{
                          name: "insurance_no",
                          className:
                            "text-uppercase rounded-0 form-control form-control",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Upload proof <span className="mand">*</span></td>
                    <td>
                      <input
                        type="file"
                        onChange={onInsuranceProofFileChange}
                      />
                      <button onClick={onInsuranceProofFileUpload}>
                        Upload!
                      </button>
                      <p className="mt-2"><a href={basefileURL+userProfile?.insurance_doc} download>{userProfile?.insurance_doc?.split(/[/]+/).pop()}</a></p>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default Profile;
