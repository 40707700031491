import logo from "../../Assets/logo.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./ForgotPassword.css";
import { urls } from "../../Config/Consts";
import { postRequest } from "../../Config/SharedFunctions";

function ForgotPassword() {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [responseFailTxt, setResponseFailTxt] = useState("");
  const [responseSuccessTxt, setResponseSuccessTxt] = useState("");

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      const user = { email };
      event.preventDefault();
      event.stopPropagation();
      //console.log(urls?.user?.forgotpw, user);
      let res = await postRequest(urls?.user?.forgotpw, user);
      if (res?.data?.response == true) {
        setResponseSuccessTxt(res?.data?.message);
        setResponseFailTxt("");
      } else {
        setResponseSuccessTxt("");
        setResponseFailTxt(res?.data?.message);
      }
    }
  };

  return (
    <Container>
      <Row className="justify-content-sm-center">
        <Col xs={12} sm={8} md={6} lg={5}>
          <div className="text-center">
            <img className="login-logo m-2 img-fluid" src={logo} alt="Logo" />
          </div>
          <Card className="forgot-card p-sm-3">
            <Card.Header>
              <Link to="/" className="float-end">
                <FontAwesomeIcon icon={faLongArrowLeft} /> &nbsp; Home
              </Link>
            </Card.Header>
            <Card.Body>
              <div>
                <h4>Forgot password ?</h4>
                <span>
                  Please enter your registered email to reset your password.
                </span>
              </div>
              <span className="text-danger">{responseFailTxt}</span>
              <span className="text-success">{responseSuccessTxt}</span>
              <Form
                id="forgot_password_form"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <Form.Group className="mb-3">
                  <InputGroup hasValidation>
                    <Form.Control
                      size="lg"
                      type="email"
                      required
                      id="email"
                      className="input-heigt mt-4"
                      placeholder="Enter email "
                      onChange={({ target }) => setEmail(target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter an email.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <div className="text-center">
                  <Button
                    size="lg"
                    className="btn-size mt-3 primary-colors"
                    variant="light"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
