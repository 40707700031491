import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import "./RestPassword.css";
function RestPassword() {
  const [validated, setValidated] = useState(false);
  const [npassword, setNewPassword] = useState("");
  const [cpassword, setConfirmPassword] = useState("");
  const eye = <FontAwesomeIcon icon={faEye} />;
  const eyeslash = <FontAwesomeIcon icon={faEyeSlash} />;
  const [isEyeslashNew, setEyeslashNew] = useState(false);

  function viewNewPassword() {
    const passwordNew = document.querySelector("input[name='npassword']");
    const type =
      passwordNew.getAttribute("type") === "password" ? "text" : "password";
    passwordNew.setAttribute("type", type);
    setEyeslashNew(!isEyeslashNew);
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      const form = document.querySelector("#reset-password-form");
      const passwordInput = document.querySelector("[name='cpassword']");
      if (npassword === cpassword) {
        console.log("Password matched");
        event.preventDefault();
        event.stopPropagation();
        const user = {
          npassword,
        };
        passwordInput.classList.remove("is-invalid");
        return false;
      } else {
        console.log("Password not matched");
        event.preventDefault();
        event.stopPropagation();
        passwordInput.classList.add("is-invalid");
        passwordInput.classList.remove("is-valid");
        form.classList.add("was-validated");
        return false;
      }
    }
  };

  return (
    <Row>
      <Col xl={6} lg={8} md={10} sm={8} xs={12} className="mx-auto">
        <Card>
          <Card.Header className="primary-colors">Reset Password</Card.Header>
          <Card.Body>
            <Form
              id="reset-password-form"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <InputGroup hasValidation className="new-password">
                  <Form.Control
                    size="lg"
                    type="password"
                    name="npassword"
                    required
                    pattern="(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    placeholder="New Password"
                    className="input-heigt"
                    onChange={({ target }) => setNewPassword(target.value)}
                  />
                  <InputGroup.Text>
                    <span onClick={viewNewPassword} id="togglePassword">
                      {isEyeslashNew ? eye : eyeslash}
                    </span>
                  </InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    Please enter new password and it must contain at least one
                    number and one uppercase and lowercase letter and one
                    special character, and at least 8 or more characters.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    size="lg"
                    type="password"
                    name="cpassword"
                    required
                    placeholder="Confirm Password"
                    className="input-heigt"
                    onChange={({ target }) => setConfirmPassword(target.value)}
                  />

                  <Form.Control.Feedback type="invalid">
                    Password not matched.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <div className="text-center">
                <Button
                  size="lg"
                  className="btn-size mt-3 primary-colors"
                  variant="light"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default RestPassword;
