import logo from "../Assets/logo.png";
import "./PDFDocument.css";
const convertMillisecToHours = (millisec) => {
  var seconds = (millisec / 1000).toFixed(0);
  var minutes = Math.floor(seconds / 60);
  var hours = "";
  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    hours = hours >= 10 ? hours : "0" + hours;
    minutes = minutes - hours * 60;
    minutes = minutes >= 10 ? minutes : "0" + minutes;
  }

  seconds = Math.floor(seconds % 60);
  seconds = seconds >= 10 ? seconds : "0" + seconds;
  if (hours != "") {
    return hours + ":" + minutes + ":" + seconds;
  }
  return "00:" + minutes + ":" + seconds;
};

const PDFDocument = (data) => (
  <div className="pdfdata">
    <table style={{ width: '500px' }}>
      <tbody>
        <tr>
          <td>
            <img src={logo} />
          </td>
          <td className="title">
            {data?.name} Trip Report to {data?.pdfData?.trip_block} block on{" "}
            {data?.pdfData?.start_datetime}
          </td>
        </tr>
      </tbody>
    </table>
    <hr />
    <table style={{ width: '500px' }}>
      <tbody>
        <tr>
          <td>Trip ID:</td>
          <td>{data?.pdfData?.trip_id}</td>
        </tr>
        <tr>
          <td>Project:</td>
          <td>{data?.pdfData?.trip_project}</td>
        </tr>
        <tr>
          <td>Block:</td>
          <td>{data?.pdfData?.trip_block}</td>
        </tr>
        <tr>
          <td>Purpose of visit:</td>
          <td>{data?.pdfData?.purposeofvisit}</td>
        </tr>
        <tr>
          <td>Mode of travel:</td>
          <td>{data?.pdfData?.mode_of_travel}</td>
        </tr>
        <tr>
          <td>Vehicle Regno:</td>
          <td>{data?.pdfData?.vehicle_regno}</td>
        </tr>
        <tr>
          <td>Start odometer:</td>
          <td>{data?.pdfData?.start_odometer}</td>
        </tr>
        <tr>
          <td>End odometer:</td>
          <td>{data?.pdfData?.end_odometer}</td>
        </tr>
        <tr>
          <td>Distance: </td>
          <td>{data?.pdfData?.end_odometer - data?.pdfData?.start_odometer}</td>
        </tr>
        <tr>
          <td>Start time</td>
          <td>{data?.pdfData?.start_datetime}</td>
        </tr>
        <tr>
          <td>Start Location: </td>
          <td>{JSON.parse(data?.pdfData?.start_location)?.address}</td>
        </tr>
        <tr>
          <td>End time</td>
          <td>{data?.pdfData?.end_datetime}</td>
        </tr>
        <tr>
          <td>End Location: </td>
          <td>{JSON.parse(data?.pdfData?.end_location)?.address}</td>
        </tr>
        <tr>
          <td>Duration: </td>
          <td>
            {convertMillisecToHours(
              Math?.abs(
                new Date(data?.pdfData?.end_datetime) -
                  new Date(data?.pdfData?.start_datetime)
              )
            )}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default PDFDocument;
