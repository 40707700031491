import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCab,
  faDownload,
  faHome,
  faUserFriends,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import "./MSpaceSidebar.css";
import bgImg from "../Assets/wassan_wta_bg.jpg";
function MSpaceSidebar() {
  const userSession = JSON.parse(sessionStorage.getItem("user"));
  //console.log(userSession);
  const [toggled, setToggled] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [broken, setBroken] = useState(
    window.matchMedia("(max-width: 800px)").matches
  );
  const location = useLocation();
  function MouseOver(event) {
    setCollapsed(false);
  }
  function MouseOut(event) {
    setCollapsed(true);
  }
  return (
    <>
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        customBreakPoint="800px"
        onBreakPoint={setBroken}
        onBackdropClick={() => setToggled(false)}
        backgroundColor="#11678D"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  color: disabled ? "#ccc" : "#fff",
                };
            },
          }}
        >
          <MenuItem
            className={
              location.pathname === "/dashboard" || location.pathname === "/"
                ? "active"
                : ""
            }
            title="Dashboard"
            component={<Link to="/dashboard" />}
            icon={<FontAwesomeIcon icon={faHome} />}
            onMouseOver={MouseOver}
            onMouseOut={MouseOut}
          >
            Dashboard
          </MenuItem>

          {userSession.role == "Admin" || userSession.role == "Team Lead" ? (
            <>
              <MenuItem
                className={location.pathname === "/users" ? "active" : ""}
                title="All Users"
                component={<Link to="/users" />}
                icon={<FontAwesomeIcon icon={faUserFriends} />}
                onMouseOver={MouseOver}
                onMouseOut={MouseOut}
              >
                All Users
              </MenuItem>
              <MenuItem
                className={(location.pathname === "/create-uesr" || location.pathname.startsWith("/create-uesr/"))? "active" : ""}
                title="Add User"
                component={<Link to="/create-uesr" />}
                icon={<FontAwesomeIcon icon={faUserPlus} />}
                onMouseOver={MouseOver}
                onMouseOut={MouseOut}
              >
                Add User
              </MenuItem>

              <MenuItem
              className={location.pathname === "/my-dpc-trips" ? "active" : ""}
              title="My DPC Trips"
              component={<Link to="/my-dpc-trips" />}
              icon={<FontAwesomeIcon icon={faCab} />}
              onMouseOver={MouseOver}
              onMouseOut={MouseOut}
            >
              {userSession.role == "Team Lead" ? "My DPC Trips" : "All users Trips"}
            </MenuItem>
            </>
          ) : (
            ""
          )}


          <MenuItem
            title="Download APK"
            component={
              <a
                download
                href={process.env.REACT_APP_APK_URL}
              />
            }
            icon={<FontAwesomeIcon icon={faDownload} />}
            onMouseOver={MouseOver}
            onMouseOut={MouseOut}
          >
            Download APK
          </MenuItem>
        </Menu>
      </Sidebar>
      <div className="leftMenuTogggle" onClick={() => setToggled(!toggled)}>
        <div>{broken && <FontAwesomeIcon icon={faBars} />}</div>
      </div>
    </>
  );
}

export default MSpaceSidebar;
