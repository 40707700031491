import instance from "../Services/Index.js";
import CryptoJS from "crypto-js";

export const postRequest = async (url, data) => {
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let res = await instance.post(url, data, config);
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getRequest = async (url, data) => {
  try {
    let res = await instance.get(url, data);
    return res?.response;
  } catch (e) {
    console.log(e);
    return e;
  }
};
export const getData = async (url) => {
  try {
    return await instance.get(url);
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const encryptData = (data, key = process.env.REACT_APP_SECRET_KEY) => {
  const cipherText = CryptoJS.AES.encrypt(data, key).toString();
  return cipherText;
};

export const decryptData = (cipherText, key = process.env.REACT_APP_SECRET_KEY) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, key);

  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
};

export const encrypt = (inputText) => {
  // const encryptedBytes = CryptoJS.AES.encrypt(
  //   inputText,
  //   process.env.REACT_APP_SECRET_KEY,
  //   {
  //     iv: process.env.REACT_APP_SECRET_IV,
  //   }
  // ).toString();

  const keySpec = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_KEY);
  const ivSpec = CryptoJS.enc.Utf8.parse(
    process.env.REACT_APP_SECRET_IV.substring(0, 16)
  );

  const encryptedBytes = CryptoJS.AES.encrypt(inputText, keySpec, {
    iv: ivSpec,
  }).toString();

  return encryptedBytes;
};

export const decrypt = (encryptedText) => {
  // const decryptedBytes = CryptoJS.AES.decrypt(
  //   encryptedText,
  //   process.env.REACT_APP_SECRET_KEY,
  //   {
  //     iv: process.env.REACT_APP_SECRET_IV,
  //   }
  // ).toString(CryptoJS.enc.Utf8);

  const keySpec = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_KEY);
  const ivSpec = CryptoJS.enc.Utf8.parse(
    process.env.REACT_APP_SECRET_IV.substring(0, 16)
  );

  const decryptedBytes = CryptoJS.AES.decrypt(encryptedText, keySpec, {
    iv: ivSpec,
  }).toString(CryptoJS.enc.Utf8);

  return decryptedBytes;
};
