import { HashRouter } from "react-router-dom";
import RouteConfig from "./Routes/RouteConfig";
import 'normalize.css';
import "./App.css";
function App() {
  return (
    <div className="App">
      <HashRouter>        
        <RouteConfig />
      </HashRouter>
    </div>
  );
}

export default App;
