import Card from "react-bootstrap/Card";
import { useEffect, useState } from "react";
import { urls } from "../../Config/Consts";
import { getData, postRequest } from "../../Config/SharedFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Form, Row, Col, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./Dashboard.css";
import {
  MapContainer,
  TileLayer,
  Marker,
  CircleMarker,
  Popup,
  Polyline,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-bs4";
import "datatables.net-buttons";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import jsPDF from "jspdf";
import PDFDocument from "../../Component/PDFDocument";
import ReactDOMServer from "react-dom/server";
function Dashboard() {
  const [userTrips, setUserTrips] = useState([]);
  const [userTripsFailed, setUserTripsFailed] = useState("");
  const [startLoc, setStartLoc] = useState([]);
  const [endLoc, setEndLoc] = useState([]);
  const [startAddress, setStartAddress] = useState([]);
  const [endAddress, setEndAddress] = useState([]);
  const [tripLoc, setTripLoc] = useState([]);
  const [currentLoc, setCurrentLoc] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [totalDistance, setTotalDistance] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [daterange, setdateDange] = useState("");

  const headers = [
    { label: "Device ID", key: "device_id" },
    { label: "Trip ID", key: "trip_id" },
    { label: "Project", key: "project" },
    { label: "Block", key: "block" },
    { label: "Mode of travel", key: "mode_of_travel" },
    { label: "Purpose of visit", key: "purposeofvisit" },
    { label: "Vehicle regno", key: "vehicle_regno" },
    { label: "Start odometer", key: "start_odometer" },
    { label: "End odometer", key: "end_odometer" },
    { label: "Distance", key: "distance" },
    { label: "Map Distance", key: "mapdistance" },
    { label: "Start date & time", key: "start_datetime" },
    { label: "End date & time", key: "end_datetime" },
    { label: "Duration", key: "duration" },
    { label: "Start location", key: "start_location" },
    { label: "End location", key: "end_location" },
    { label: "Date of trip update", key: "date_time" },
  ];

  let dataTrips = [];
  const downloadPDF = (data) => {
    console.log(data);
    //render(<PDFDocument pdfData={data} />);
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    doc.setFont("Inter-Regular", "normal");
    // Convert React component to HTML
    const reactHtml = ReactDOMServer.renderToString(
      <PDFDocument pdfData={data} />
    );

    // Convert HTML to PDF
    const element = document.createElement("div");
    element.innerHTML = reactHtml;

    doc.html(element, {
      tableWidth: "100%",
      async callback(doc) {
        await doc.save(
          "Trip Report to " +
            data?.trip_block +
            "block on " +
            data?.pdfData?.start_datetime
        );
      },
    });
  };
  const handleShow = (data) => {
    setCurrentLoc(data);
    //console.log(currentLoc);
    const stLoc = [];
    const edLoc = [];
    stLoc.push(JSON.parse(data.start_location).latitude);
    stLoc.push(JSON.parse(data.start_location).longitude);
    edLoc.push(JSON.parse(data.end_location).latitude);
    edLoc.push(JSON.parse(data.end_location).longitude);
    setStartAddress(JSON.parse(data.start_location).address);
    setEndAddress(JSON.parse(data.end_location).address);
    setStartLoc(stLoc);
    setEndLoc(edLoc);
    setTripLoc(JSON.parse(data.trip_locations));
    setShow(true);
  };
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const userSession = JSON.parse(sessionStorage.getItem("user"));
  const user = {
    userid: userSession.user_id,
    startdate: startDate,
    enddate: endDate,
  };
  const getTripDetails = async () => {
    $("#dashboard thead tr.filters").remove();
    $("#dashboard").DataTable().destroy();
    let res = await postRequest(urls?.trip?.tripget, user);
    //console.log(res?.data?.message);
    if (res?.data?.response == true) {
      setUserTrips(res?.data?.message);
      const timeoutId = setTimeout(() => {
        loadDataTable();
      }, 500);
    } else {
      setUserTripsFailed(res?.data?.message);
    }
  };
  useEffect(() => {
    //console.log("getTripDetails");
    getTripDetails();
  }, []);

  const convertMillisecToHours = (millisec) => {
    var seconds = (millisec / 1000).toFixed(0);
    var minutes = Math.floor(seconds / 60);
    var hours = "";
    if (minutes > 59) {
      hours = Math.floor(minutes / 60);
      hours = hours >= 10 ? hours : "0" + hours;
      minutes = minutes - hours * 60;
      minutes = minutes >= 10 ? minutes : "0" + minutes;
    }

    seconds = Math.floor(seconds % 60);
    seconds = seconds >= 10 ? seconds : "0" + seconds;
    if (hours != "") {
      return hours + ":" + minutes + ":" + seconds;
    }
    return "00:" + minutes + ":" + seconds;
  };

  const getDateRangeData = () => {
    let startDateFull = new Date(startDate);
    let endDateFull = new Date(endDate);

    if (startDateFull > endDateFull) {
      alert("End date need to be bigger then start date");
    } else {
      getTripDetails();
    }
  };

  function loadDataTable() {
    let daterangetitle = "";
    if (startDate != null && endDate != null) {
      setdateDange("(" + startDate + " - " + endDate + ")");
      daterangetitle = " (" + startDate + " - " + endDate + ")";
    }
    $("#dashboard thead tr")
      .clone(true)
      .addClass("filters")
      .appendTo("#dashboard thead");

    const newTable = $("#dashboard").DataTable({
      orderCellsTop: true,
      fixedHeader: true,
      dom: "Bfrtip",
      buttons: [
        {
          extend: "pdfHtml5",
          title: userSession.name + " Field Visit Trips" + daterangetitle,
          orientation: "landscape",
          pageSize: "LEGAL",
          exportOptions: {
            columns: "th:not(:last-child)",
          },
        },
      ],
      initComplete: function () {
        var api = this.api();

        // For each column
        api
          .columns()
          .eq(0)
          .each(function (colIdx) {
            // Set the header cell to contain the input element
            var cell = $(".filters th").eq(
              $(api.column(colIdx).header()).index()
            );
            var title = $(cell).text();
            $(cell).html('<input type="text" placeholder="' + title + '" />');

            // On every keypress in this input
            $(
              "input",
              $(".filters th").eq($(api.column(colIdx).header()).index())
            )
              .off("keyup change")
              .on("change", function (e) {
                // Get the search value
                var cursorPosition = this.selectionStart;
                $(this).attr("title", $(this).val());
                var regexr = "({search})"; //$(this).parents('th').find('select').val();

                // Search the column for that value
                api
                  .column(colIdx)
                  .search(
                    this.value != ""
                      ? regexr.replace("{search}", "(((" + this.value + ")))")
                      : "",
                    this.value != "",
                    this.value == ""
                  )
                  .draw();
              })
              .on("keyup", function (e) {
                e.stopPropagation();
                var cursorPosition = this.selectionStart;
                $(this).trigger("change");
                $(this)
                  .focus()[0]
                  .setSelectionRange(cursorPosition, cursorPosition);
              });
          });
      },
    });
  }

  const startIcon = new L.Icon({
    iconUrl: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png", // Provide the URL to your custom icon image
    iconSize: [32, 32], // Set the size of the icon
    iconAnchor: [16, 32], // Set the anchor point of the icon
    popupAnchor: [0, -32], // Set the popup anchor point
  });

  const endIcon = new L.Icon({
    iconUrl: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png", // Provide the URL to your custom icon image
    iconSize: [32, 32], // Set the size of the icon
    iconAnchor: [16, 32], // Set the anchor point of the icon
    popupAnchor: [0, -32], // Set the popup anchor point
  });

  let routeCoordinates = [];
  routeCoordinates.push(startLoc);
  {
    tripLoc?.map((obj, inc) =>
      routeCoordinates.push([obj?.latitude, obj?.longitude])
    );
  }
  routeCoordinates.push(endLoc);

  useEffect(() => {
    //console.log("routeCoordinates", routeCoordinates);
    setTotalDistance(calculateDistance(routeCoordinates));
  }, [startLoc]);
  const calculateDistance = (routeCoordinates) => {
    let distance = 0;
    for (let i = 0; i < routeCoordinates.length - 1; i++) {
      const coord1 = routeCoordinates[i];
      const coord2 = routeCoordinates[i + 1];
      //console.log(parseFloat(calculateHaversineDistance(coord1, coord2)));
      distance += parseFloat(calculateHaversineDistance(coord1, coord2));
    }
    return distance;
  };
  const calculateHaversineDistance = ([lat1, lon1], [lat2, lon2]) => {
    const earthRadius = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;
    return distance;
  };

  {
    userTrips?.map((e, i) => {
      let rc = [];
      rc.push([
        JSON.parse(e?.start_location).latitude,
        JSON.parse(e?.start_location).longitude,
      ]);
      {
        JSON.parse(e?.trip_locations)?.map((obj, inc) =>
          rc.push([obj?.latitude, obj?.longitude])
        );
      }
      rc.push([
        JSON.parse(e?.end_location).latitude,
        JSON.parse(e?.end_location).longitude,
      ]);

      dataTrips.push({
        device_id: e?.device_id,
        trip_id: e?.trip_id,
        project: e?.trip_project,
        block: e?.trip_block,
        mode_of_travel: e?.mode_of_travel,
        purposeofvisit: e?.purposeofvisit,
        vehicle_regno: e?.vehicle_regno,
        start_odometer: e?.start_odometer,
        end_odometer: e?.end_odometer,
        distance: e?.end_odometer - e?.start_odometer,
        mapdistance: calculateDistance(rc),
        start_datetime: e?.start_datetime,
        end_datetime: e?.end_datetime,
        duration: convertMillisecToHours(
          Math.abs(new Date(e?.end_datetime) - new Date(e?.start_datetime))
        ),
        start_location: JSON.parse(e?.start_location).address,
        end_location: JSON.parse(e?.end_location).address,
        date_time: e?.date_time,
      });
    });
  }
  const resetFilters = () => {
    window.location.reload();
  };

  const calculateMapDistance = (
    odoDistance,
    startlocaion,
    endlocation,
    triplocations
  ) => {
    let hugeDiff = "hugediff";
    let routeCo = [];
    routeCo.push(startlocaion);
    {
      triplocations?.map((obj, inc) =>
        routeCo.push([obj?.latitude, obj?.longitude])
      );
    }
    routeCo.push(endlocation);
    const mapDist = calculateDistance(routeCo).toFixed(2);

    let diff = odoDistance - mapDist;
    hugeDiff = diff > 20 ? "hugediff":"considered";
    return [hugeDiff, mapDist];
  };
  return (
    <>
      {show && (
        <Modal show={show} size="lg" onHide={handleClose}>
          <Modal.Header closeButton>
            {" "}
            {userSession.name} Trip Report to {currentLoc?.trip_block} block on{" "}
            {currentLoc?.start_datetime}
          </Modal.Header>
          <Modal.Body>
            <MapContainer
              center={startLoc}
              zoom={16}
              style={{ height: "80vh", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker
                position={startLoc}
                icon={startIcon}
                title="Start Location"
              >
                <Popup>
                  <div style={{ margin: "2rem" }}>
                    <p>Trip ID: {currentLoc?.trip_id}</p>
                    <p>Project: {currentLoc?.trip_project}</p>
                    <p>Block: {currentLoc?.trip_block}</p>
                    <p>Purpose of visit: {currentLoc?.purposeofvisit}</p>
                    <p>Mode of travel: {currentLoc?.mode_of_travel}</p>
                    <p>Vehicle Regno: {currentLoc?.vehicle_regno}</p>
                    <p>Start odometer: {currentLoc?.start_odometer}</p>
                    <p>End odometer: {currentLoc?.end_odometer}</p>
                    <p>
                      Distance:{" "}
                      {currentLoc?.end_odometer - currentLoc?.start_odometer}
                    </p>
                    <p>Map Distance: {totalDistance}</p>
                    <p>Start time: {currentLoc?.start_datetime}</p>
                    <p>Start Location:{startAddress}</p>
                    <p>End time: {currentLoc?.end_datetime}</p>
                    <p>End Location:{endAddress}</p>
                    <p>
                      Duration:{" "}
                      {convertMillisecToHours(
                        Math.abs(
                          new Date(currentLoc?.end_datetime) -
                            new Date(currentLoc?.start_datetime)
                        )
                      )}
                    </p>
                  </div>
                </Popup>
              </Marker>

              <Marker position={endLoc} icon={endIcon} title="End Location">
                <Popup>
                  <div style={{ margin: "2rem" }}>
                    <p>Trip ID: {currentLoc?.trip_id}</p>
                    <p>Project: {currentLoc?.trip_project}</p>
                    <p>Block: {currentLoc?.trip_block}</p>
                    <p>Purpose of visit: {currentLoc?.purposeofvisit}</p>
                    <p>Mode of travel: {currentLoc?.mode_of_travel}</p>
                    <p>Vehicle Regno: {currentLoc?.vehicle_regno}</p>
                    <p>Start odometer: {currentLoc?.start_odometer}</p>
                    <p>End odometer: {currentLoc?.end_odometer}</p>
                    <p>
                      Distance:{" "}
                      {currentLoc?.end_odometer - currentLoc?.start_odometer}
                    </p>
                    <p>Map Distance: {totalDistance}</p>
                    <p>Start time: {currentLoc?.start_datetime}</p>
                    <p>Start Location: {startAddress}</p>
                    <p>End time: {currentLoc?.end_datetime}</p>
                    <p>End Location: {endAddress}</p>
                    <p>
                      Duration:{" "}
                      {convertMillisecToHours(
                        Math.abs(
                          new Date(currentLoc?.end_datetime) -
                            new Date(currentLoc?.start_datetime)
                        )
                      )}
                    </p>
                  </div>
                </Popup>
              </Marker>
              <Polyline
                pathOptions={{ color: "blue" }}
                positions={routeCoordinates}
              />
              {tripLoc?.map((obj, inc) => (
                <CircleMarker
                  center={[obj?.latitude, obj?.longitude]}
                  radius={1}
                  pathOptions={{
                    color: "blue", // Change the circle color
                    fillColor: "blue", // Change the fill color
                    fillOpacity: 0.6, // Adjust the fill opacity
                  }}
                />
              ))}
            </MapContainer>
          </Modal.Body>
        </Modal>
      )}
      <Card className="mt-3">
        <Card.Header className="primary-colors">
          <Row>
            <div className="col-md-5 dashboard-title">
              {userSession.name} Field Visit Trips {daterange}
            </div>
            <div className="col-md-7">
              <Row>
                <Col sm={5}>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm={5}
                      className="px-0"
                      style={{ textAlign: "right" }}
                    >
                      Start Date: &nbsp;
                    </Form.Label>
                    <Col sm={7} className="px-0">
                      <Form.Control
                        type="date"
                        name="startDate"
                        onChange={({ target }) => setStartDate(target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm={5}
                      className="px-0"
                      style={{ textAlign: "right" }}
                    >
                      End Date: &nbsp;
                    </Form.Label>
                    <Col sm={7} className="px-0">
                      <Form.Control
                        type="date"
                        name="endDate"
                        onChange={({ target }) => setEndDate(target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Button
                    variant="light"
                    onClick={getDateRangeData}
                    className="me-1"
                  >
                    Search
                  </Button>
                  <Button variant="light" onClick={resetFilters}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </div>
          </Row>
        </Card.Header>
        <Card.Body>
          <table
            hover
            id="dashboard"
            className="trip-table display table table-striped table-responsive"
          >
            <thead>
              <tr>
                <th>Project</th>
                <th>Block</th>
                <th>Mode of travel</th>
                <th>Vehicle regno</th>
                <th>Start odometer</th>
                <th>End odometer</th>
                <th>Distance</th>
                <th>Map Distance</th>
                <th>Start date & time</th>
                <th>End date & time</th>
                <th>Duration</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {userTrips?.map((e, i) => (
                <tr
                  key={i} 
                  className={
                    calculateMapDistance(
                      e?.end_odometer - e.start_odometer,
                      [
                        JSON.parse(e?.start_location).latitude,
                        JSON.parse(e?.start_location).longitude,
                      ],
                      [
                        JSON.parse(e?.end_location).latitude,
                        JSON.parse(e?.end_location).longitude,
                      ],
                      JSON.parse(e?.trip_locations)
                    )[0]
                  }
                >
                  <td>{e?.trip_project}</td>
                  <td>{e?.trip_block}</td>
                  <td>{e?.mode_of_travel}</td>
                  <td>{e?.vehicle_regno}</td>
                  <td>{e?.start_odometer}</td>
                  <td>{e?.end_odometer}</td>
                  <td>{e?.end_odometer - e.start_odometer}</td>
                  <td>
                    {
                      calculateMapDistance(
                        e?.end_odometer - e.start_odometer,
                        [
                          JSON.parse(e?.start_location).latitude,
                          JSON.parse(e?.start_location).longitude,
                        ],
                        [
                          JSON.parse(e?.end_location).latitude,
                          JSON.parse(e?.end_location).longitude,
                        ],
                        JSON.parse(e?.trip_locations)
                      )[1]
                    }
                  </td>
                  <td>{e?.start_datetime}</td>
                  <td>{e?.end_datetime}</td>

                  <td>
                    {convertMillisecToHours(
                      Math.abs(
                        new Date(e?.end_datetime) - new Date(e?.start_datetime)
                      )
                    )}
                  </td>
                  <td>
                    {" "}
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      onClick={() => handleShow(e)}
                    />{" "}
                    &nbsp;
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      onClick={() => downloadPDF(e)}
                    />
                    {/* <DownloadPDFButton pdfData={e} /> */}
                  </td>
                </tr>
              ))}
              {userTripsFailed != "" ? (
                <tr>
                  <td colSpan="11">{userTripsFailed}</td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </>
  );
}

export default Dashboard;
