import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Profile from "../Pages/Profile/Profile";
import Login from "../Pages/Login/Login";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import UserAuthenticatedRoute from "../Routes/UserAuthenticatedRoute";
import Register from "../Pages/Register/Register";
import Dashboard from "../Pages/Dashboard/dashboard";
import RestPassword from "../Pages/RestPassword/RestPassword";
import ChangePassword from "../Pages/ChangePassword/ChangePassword";
import Header from "../Container/Header";
import MSpaceSidebar from "../Container/MSpaceSidebar";
import MyDPC from "../Pages/MyDPC/MyDPC";
import Users from "../Pages/Users/Users";
const RouteConfig = () => {
  const location = useLocation();
  const nav = useNavigate();

  if (location.pathname === "/logout") {
    sessionStorage.clear();
    sessionStorage.clear();
    nav("/login");
  }
  const userSession = JSON.parse(sessionStorage?.getItem("user"));
  let auth = sessionStorage.getItem("token");
  if (auth != null) {
    if (location.pathname === "/login") {
      nav("/dashboard");
    }
    return (
      <UserAuthenticatedRoute>
        <div>
          <main className="main-content">
            <MSpaceSidebar />
            <section className="content-section m-1">
          <Header />
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/create-uesr" element={(userSession.role == "Admin" || userSession.role == "Team Lead" ? <Register /> : <><h3 className="mt-5 text-center">Please login as an admin user(State Coordinator) or Team Lead(Regional Coordinator) to access this page.</h3></>)} />
                <Route path="/create-uesr/:id" element={(userSession.role == "Admin" || userSession.role == "Team Lead" ? <Register /> : <><h3 className="mt-5 text-center">Please login as an admin user(State Coordinator) or Team Lead(Regional Coordinator) to access this page.</h3></>)} />
                <Route path="/users" element={(userSession.role == "Admin" || userSession.role == "Team Lead" ? <Users /> : <><h3 className="mt-5 text-center">Please login as an admin user(State Coordinator) or Team Lead(Regional Coordinator) to access this page.</h3></>)} />
                <Route path="/my-dpc-trips" element={(userSession.role == "Admin" || userSession.role == "Team Lead" ? <MyDPC /> : <><h3 className="mt-5 text-center">Please login as an admin user(State Coordinator) or Team Lead(Regional Coordinator) to access this page.</h3></>)} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/resetpassword" element={<RestPassword />} />
                <Route path="/changepassword" element={<ChangePassword />} />
                <Route
                  path="*"
                  element={
                    <h3 className="mt-5 text-center">
                      {" "}
                      404 - Page Not Found or You do not have permission.
                    </h3>
                  }
                />
              </Routes>
            </section>
          </main>
        </div>
      </UserAuthenticatedRoute>
    );
  } else {
    //console.log("auth", auth);
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="*" element={<Login />} />
      </Routes>
    );
  }
};

export default RouteConfig;
