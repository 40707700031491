import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../Assets/logo.png";
import profilepic from "../Assets/profilepic.jpg";
import "./Header.css";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faKey,
  faMobileAlt,
  faSignOut,
  faUserAlt,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
function Header() {
  const userdata = JSON.parse(sessionStorage.getItem('user'));
  return (
    <Row>
      <Container>
        <Row>
          <Col xs={6} md={2}>
            <Link to="/">
              <img className="logo m-2 img-fluid" src={logo} alt="Logo" />
            </Link>
          </Col>
          <Col md={8} className="text-center d-none d-md-block">
            <h5 className="mt-3 fw-bold primary-color">Welcome to Field Visit Portal</h5>
          </Col>
          <Col xs={6} md={2} className="text-right">
            <Dropdown>
              <Dropdown.Toggle variant="none" id="user-menu">
                <img
                  className="profilepic me-2 img-fluid img-thumbnail mt-1"
                  src={profilepic}
                  alt="Profile pic"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.ItemText>
                  <FontAwesomeIcon icon={faUserAlt} /> {userdata.name}
                  <br />
                  <FontAwesomeIcon icon={faEnvelope} />  {userdata.email}
                  <br />
                  <FontAwesomeIcon icon={faMobileAlt} />  {userdata.mobile}
                  <br />
                  <Dropdown.Divider />
                  <ul>
                    <li>
                      <Link to="/profile">
                        <FontAwesomeIcon icon={faUserCheck} /> Profile
                      </Link>
                    </li>
                    <li>
                      <Link to="/changepassword">
                        <FontAwesomeIcon icon={faKey} /> Change Password
                      </Link>
                    </li>
                    <li>
                      <Link to="/logout">
                        <FontAwesomeIcon icon={faSignOut} /> Sign Out
                      </Link>
                    </li>
                  </ul>
                </Dropdown.ItemText>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>
    </Row>
  );
}

export default Header;
