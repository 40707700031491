import Card from "react-bootstrap/Card";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCab, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import "./MyDPC.css";
import { urls } from "../../Config/Consts";
import { getData, postRequest } from "../../Config/SharedFunctions";
import { useEffect, useReducer } from "react";
import { InputGroup, Table, Form, Row, Col, Button } from "react-bootstrap";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-bs4";
import "datatables.net-buttons";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
function MyDPC() {
  const [trips, setTrips] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const userSession = JSON.parse(sessionStorage.getItem("user"));
  const [daterange, setdateDange] = useState("");
  const user = {
    userid: userSession.user_id,
    startdate: startDate,
    enddate: endDate,
  };
  const getTripDetails = async () => {
    $("#mydpc thead tr.filters").remove();
    $("#mydpc").DataTable().destroy();

    let res = await postRequest(urls?.trip?.tripgetall, user);
    if (res?.data?.response == true) {
      console.log(res?.data?.message);
      setTrips(res?.data?.message);

      const timeoutId = setTimeout(() => {
        loadDataTable();
      }, 500);
    } else {
      console.log(res?.data?.message);
    }
  };

  const getDateRangeData = () => {
    let startDateFull = new Date(startDate);
    let endDateFull = new Date(endDate);

    if (startDateFull > endDateFull) {
      alert("End date need to be bigger then start date");
    } else {
      getTripDetails();
    }
  };

  useEffect(() => {
    getTripDetails();
  }, []);
  const calculateDistance = (routeCoordinates) => {
    let distance = 0;
    for (let i = 0; i < routeCoordinates.length - 1; i++) {
      const coord1 = routeCoordinates[i];
      const coord2 = routeCoordinates[i + 1];
      //console.log(parseFloat(calculateHaversineDistance(coord1, coord2)));
      distance += parseFloat(calculateHaversineDistance(coord1, coord2));
    }
    return distance;
  };

  const calculateHaversineDistance = ([lat1, lon1], [lat2, lon2]) => {
    const earthRadius = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;
    return distance;
  };
  const convertMillisecToHours = (millisec) => {
    var seconds = (millisec / 1000).toFixed(0);
    var minutes = Math.floor(seconds / 60);
    var hours = "";
    if (minutes > 59) {
      hours = Math.floor(minutes / 60);
      hours = hours >= 10 ? hours : "0" + hours;
      minutes = minutes - hours * 60;
      minutes = minutes >= 10 ? minutes : "0" + minutes;
    }

    seconds = Math.floor(seconds % 60);
    seconds = seconds >= 10 ? seconds : "0" + seconds;
    if (hours != "") {
      return hours + ":" + minutes + ":" + seconds;
    }
    return "00:" + minutes + ":" + seconds;
  };

  function loadDataTable() {
    let daterangetitle = "";
    if (startDate != "" && endDate != "") {
      setdateDange("(" + startDate + " - " + endDate + ")");
      daterangetitle = " (" + startDate + " - " + endDate + ")";
    }

    $("#mydpc thead tr")
      .clone(true)
      .addClass("filters")
      .appendTo("#mydpc thead");

    const newTable = $("#mydpc").DataTable({
      orderCellsTop: true,
      fixedHeader: true,
      dom: "Bfrtip",
      buttons: [
        {
          extend: "excelHtml5",
          title: userSession.name + " Field Visit Trips" + daterangetitle,
          pageSize: "LEGAL",
          exportOptions: {
            columns: "th:not(:last-child)",
          },
        },
        {
          extend: "pdfHtml5",
          title: userSession.name + " Field Visit Trips" + daterangetitle,
          orientation: "landscape",
          pageSize: "LEGAL",
        },
      ],
      initComplete: function () {
        var api = this.api();

        // For each column
        api
          .columns()
          .eq(0)
          .each(function (colIdx) {
            // Set the header cell to contain the input element
            var cell = $(".filters th").eq(
              $(api.column(colIdx).header()).index()
            );
            var title = $(cell).text();
            $(cell).html('<input type="text" placeholder="' + title + '" />');

            // On every keypress in this input
            $(
              "input",
              $(".filters th").eq($(api.column(colIdx).header()).index())
            )
              .off("keyup change")
              .on("change", function (e) {
                // Get the search value
                var cursorPosition = this.selectionStart;
                $(this).attr("title", $(this).val());
                var regexr = "({search})"; //$(this).parents('th').find('select').val();

                // Search the column for that value
                api
                  .column(colIdx)
                  .search(
                    this.value != ""
                      ? regexr.replace("{search}", "(((" + this.value + ")))")
                      : "",
                    this.value != "",
                    this.value == ""
                  )
                  .draw();
              })
              .on("keyup", function (e) {
                e.stopPropagation();
                var cursorPosition = this.selectionStart;
                $(this).trigger("change");
                $(this)
                  .focus()[0]
                  .setSelectionRange(cursorPosition, cursorPosition);
              });
          });
      },
    });
  }
  const resetFilters = () => {
    window.location.reload();
  };
  const calculateMapDistance = (
    odoDistance,
    startlocaion,
    endlocation,
    triplocations
  ) => {
    let hugeDiff = "hugediff";
    let routeCo = [];
    routeCo.push(startlocaion);
    {
      triplocations?.map((obj, inc) =>
        routeCo.push([obj?.latitude, obj?.longitude])
      );
    }
    routeCo.push(endlocation);
    const mapDist = calculateDistance(routeCo).toFixed(2);

    let diff = odoDistance - mapDist;
    hugeDiff = diff > 20 ? "hugediff":"considered";
    return [hugeDiff, mapDist];
  };
  return (
    <Card className="mt-2">
      <Card.Header className="primary-colors">
        <Row>
          <div className="col-md-5 mydpc-title">
            <FontAwesomeIcon icon={faCab} /> {userSession?.location} District
            Program Coordinators Trips {daterange}
          </div>
          <div className="col-md-7">
            <Row>
              <Col sm={5}>
                <Form.Group as={Row}>
                  <Form.Label
                    className="px-0"
                    column
                    sm={5}
                    style={{ textAlign: "right" }}
                  >
                    Start Date: &nbsp;
                  </Form.Label>
                  <Col sm={7} className="px-0">
                    <Form.Control
                      type="date"
                      value={startDate}
                      name="startDate"
                      onChange={({ target }) => setStartDate(target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group as={Row}>
                  <Form.Label
                    className="px-0"
                    column
                    sm={5}
                    style={{ textAlign: "right" }}
                  >
                    End Date: &nbsp;
                  </Form.Label>
                  <Col sm={7} className="px-0">
                    <Form.Control
                      type="date"
                      name="endDate"
                      value={endDate}
                      onChange={({ target }) => setEndDate(target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Button
                  variant="light"
                  className="me-1"
                  onClick={getDateRangeData}
                >
                  Search
                </Button>
                <Button variant="light" onClick={resetFilters}>
                  Reset
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
      </Card.Header>
      <Card.Body>
        <Table
          id="mydpc"
          className="trip-table display table table-striped table-responsive"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Project</th>
              <th>Block</th>
              <th>Mode of travel</th>
              <th>Vehicle regno</th>
              <th>Start odometer</th>
              <th>End odometer</th>
              <th>Distance</th>
              <th>Map Distance</th>
              <th>Start date & time</th>
              <th>End date & time</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            {trips?.map((e, i) => (
              <tr key={i} className={
                    calculateMapDistance(
                      e?.tripendodometer - e.tripstartodometer,
                      [
                        JSON.parse(e?.tripstartlocation).latitude,
                        JSON.parse(e?.tripstartlocation).longitude,
                      ],
                      [
                        JSON.parse(e?.tripendlocation).latitude,
                        JSON.parse(e?.tripendlocation).longitude,
                      ],
                      JSON.parse(e?.triplocations)
                    )[0]
                  }>
                <td>{e?.username}</td>
                <td>{e?.tripproject}</td>
                <td>{e?.tripblock}</td>
                <td>{e?.tripmodeoftravel}</td>
                <td>{e?.tripvehicleregno}</td>
                <td>{e?.tripstartodometer}</td>
                <td>{e?.tripendodometer}</td>
                <td>{e?.tripendodometer - e.tripstartodometer}</td>
                <td>
                    {
                      calculateMapDistance(
                        e?.tripendodometer - e.tripstartodometer,
                        [
                          JSON.parse(e?.tripstartlocation).latitude,
                          JSON.parse(e?.tripstartlocation).longitude,
                        ],
                        [
                          JSON.parse(e?.tripendlocation).latitude,
                          JSON.parse(e?.tripendlocation).longitude,
                        ],
                        JSON.parse(e?.triplocations)
                      )[1]
                    }
                  </td>
                <td>{e?.tripstartdatetime}</td>
                <td>{e?.tripenddatetime}</td>
                <td>
                  {convertMillisecToHours(
                    Math.abs(
                      new Date(e?.tripenddatetime) -
                        new Date(e?.tripstartdatetime)
                    )
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

export default MyDPC;
