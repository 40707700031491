import loginImg from "../../Assets/travel.jpeg";
import logo from "../../Assets/logo.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Login.css";
import { urls } from "../../Config/Consts";
import { postRequest } from "../../Config/SharedFunctions";

function Login() {
  const [validated, setValidated] = useState(false);
  const [isEyeslash, setEyeslash] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState();
  const [loginSatus, setLoginStatus] = useState("");
  const nav = useNavigate();
  const location = useLocation();
  const eye = <FontAwesomeIcon icon={faEye} />;
  const eyeslash = <FontAwesomeIcon icon={faEyeSlash} />;
  const redirectpath = location.pathname;

  function viewPassword() {
    const passwordV = document.querySelector("input[name='password']");
    const type =
      passwordV.getAttribute("type") === "password" ? "text" : "password";
    passwordV.setAttribute("type", type);
    setEyeslash(!isEyeslash);
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      const user = { username, password };
      event.preventDefault();
      event.stopPropagation();
      let res = await postRequest(urls?.user?.login, user);
      if (res?.data?.response == true) {
        sessionStorage.setItem("token", res?.data?.token);
        sessionStorage.setItem("user", JSON.stringify(res?.data?.user));
        nav(redirectpath);
      } else {
        setLoginStatus(res?.data?.message);
      }
    }
  };

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser(foundUser);
    }
  }, []);

  // if (user) {
  //   return <div>{user.name} is loggged in</div>;
  // }

  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="text-center">
          <div className="m-xl-5 m-lg-4 m-md-2">
            <img className="login-logo img-fluid m-2" src={logo} alt="Logo" />

            <h2 className="d-none d-md-block">Field Visit Portal</h2>

            <img
              className="img-fluid d-none d-md-block"
              src={loginImg}
              alt="welome banner"
            />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <Card className="primary-colors px-sm-3 px-md-4 px-lg-5 py-lg-3 my-lg-5 my-md-2 mt-md-2 mt-xl-5">
            <Card.Body>
              <div className="text-center mb-xl-5 mb-lg-4">
                <h1>Sign in</h1>
                <span>To access your dashboard.</span>
              </div>

              <Form
                id="login-form"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <Form.Label className="fw-bold text-danger">
                  {loginSatus}
                </Form.Label>
                <Form.Group className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      size="lg"
                      type="text"
                      required
                      id="email"
                      className="input-heigt"
                      placeholder="Enter email"
                      onChange={({ target }) => setUsername(target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter email or username.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      size="lg"
                      type="password"
                      name="password"
                      required
                      placeholder="Password"
                      className="input-heigt"
                      onChange={({ target }) => setPassword(target.value)}
                    />
                    <InputGroup.Text>
                      <span onClick={viewPassword} id="togglePassword">
                        {isEyeslash ? eye : eyeslash}
                      </span>
                    </InputGroup.Text>
                    <Form.Control.Feedback type="invalid">
                      Please choose a password.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <div>
                  <Link to="/forgotpassword" className="forgot-password">
                    Forgot Password ?
                  </Link>
                  {/* <a href="/forgotpassword" className="forgot-password">
                    Forgot Password ?
                  </a> */}
                </div>
                <div className="text-center">
                  <Button
                    size="lg"
                    className="btn-size mt-3"
                    variant="light"
                    type="submit"
                  >
                    SIGN IN
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
