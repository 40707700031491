export const baseURL = process.env.REACT_APP_API_URL;
export const basefileURL = process.env.REACT_APP_API_ASSETS_URL;

export const urls = {
  user: {
    login: "user/login",
    forgotpw: "user/forgotpassord",
    changepw: "user/changepassword",
    register: "user/register",
    profile: "user/profile",
    uprofile: "user/updateprofile",
    uploadprofile: "user/uploadprofile",
    getUsers: "user/getusers",
    getUser: "user/getuser",
    deleteUser: "user/deleteuser"
  },
  general: {
    designation: "general/designation",
    district: "general/district",
    projects: "general/projectslist"
  },
  trip: {
    tripadd: "trip/add",
    tripget: "trip/get",
    tripgetall: "trip/getall"
  }
};
