import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faLongArrowAltLeft,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import "./Register.css";
import { urls } from "../../Config/Consts";
import { getData, postRequest } from "../../Config/SharedFunctions";
import { useEffect } from "react";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
function Register() {
  const [validated, setValidated] = useState(false);
  const [fname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [designation, setDesignation] = useState("");
  const [password, setPassword] = useState("");
  // const [responseFailTxt, setResponseFailTxt] = useState("");
  // const [responseSuccessTxt, setResponseSuccessTxt] = useState("");
  const [designationlist, setDesignationlist] = useState([]);
  const [districtlist, setDistrictlist] = useState([]);
  const [projectslist, setProjectslist] = useState([]);
  const [userdata, setUserdata] = useState([]);
  const [rc, setRC] = useState(false);
  const [projects, setSelectedProjects] = useState([]);
  const [locations, setSelectedLocations] = useState([]);
  const [action, setUserAction] = useState("create");
  const userSession = JSON.parse(sessionStorage.getItem("user"));
  let navigate = useNavigate();
  const { id } = useParams();
  const changeDesignation = (desig) => {
    setDesignation(desig);
    if (desig == "Regional Coordinator,Team Lead") {
      setRC(true);
    } else {
      setRC(false);
    }
  };
  const getDesignationList = async () => {
    let res = await getData(urls?.general?.designation);
    if (res?.data?.response == true) {
      //console.log(res?.data?.message);
      setDesignationlist(res?.data?.message);
    } else {
      console.log(res?.data?.message);
    }
  };
  const getDistrictList = async () => {
    if (userSession.role == "Team Lead") {
      let dist = userSession.districts.split(",");
      setDistrictlist(dist);
    } else {
      let resp = await getData(urls?.general?.district);
      if (resp?.data?.response == true) {
        //console.log(resp?.data?.message);
        setDistrictlist([
          ...new Set(resp?.data?.message.map((e, i) => e.district)),
        ]);
      } else {
        console.log(resp?.data?.message);
      }
    }
  };
  const getProjectsList = async () => {
    let resp = await getData(urls?.general?.projects);
    if (resp?.data?.response == true) {
      //console.log(resp?.data?.message);
      setProjectslist([
        ...new Set(resp?.data?.message.map((e, i) => e.project_name)),
      ]);
    } else {
      console.log(resp?.data?.message);
    }
  };
  const getUserData = async (id) => {
    const data = { id: id };
    let resp = await postRequest(urls?.user?.getUser, data);
    if (resp?.data?.response == true) {
      //console.log("User Data", resp?.data?.message);
      let defaultProjItems = [];
      {
        resp?.data?.message?.projects?.map((e, i) =>
          defaultProjItems.push({ value: e, label: e })
        );
      }
      setSelectedProjects(defaultProjItems);
      setUserdata(resp?.data?.message);
      setDesignation(
        resp?.data?.message?.designation + "," + resp?.data?.message?.role
      );
      if (resp?.data?.message?.designation == "Regional Coordinator") {
        setRC(true);
      }
      let defaultLocations = [];
      {
        resp?.data?.message?.locations?.map((e, i) =>
          defaultLocations.push({ value: e, label: e })
        );
      }
      setSelectedLocations(defaultLocations);
      setFullName(resp?.data?.message?.fname);
      setEmail(resp?.data?.message?.email);
      setMobile(resp?.data?.message?.mobile);
    } else {
      setUserdata([]);
      //console.log(resp?.data?.message);
    }
  };
  useEffect(() => {
    setUserdata([]);
    if (userSession.role == "Team Lead") {
      const tlAccess = [];
      tlAccess.push({
        designation: "District Program Coordinator",
        role: "Individual (District Team Lead)",
      });
      tlAccess.push({
        designation: "Additional District Program Coordinator",
        role: "Individual",
      });
      setDesignationlist(tlAccess);
      //getDesignationList();
    } else {
      getDesignationList();
    }
    getDistrictList();
    getProjectsList();
  }, []);

  useEffect(() => {
    if (id != undefined) {
      setUserAction("update");
      getUserData(id);
    } else {
      setUserAction("create");
      setUserdata([]);
    }
  }, [id]);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      const user = {
        fname,
        email,
        mobile,
        password,
        locations: JSON.stringify(locations),
        designation,
        projects: JSON.stringify(projects),
        action,
      };

      event.preventDefault();
      event.stopPropagation();
      //console.log(urls?.user?.register, user);
      let res = await postRequest(urls?.user?.register, user);
      if (res?.data?.response == true) {
        setSelectedLocations(null);
        setSelectedProjects(null);
        form.reset();
        // setResponseSuccessTxt(res?.data?.message);
        // setResponseFailTxt("");
        Swal.fire({
          icon: "info",
          title: "Wow...!",
          text: res?.data?.message,
        });
      } else {
        // setResponseSuccessTxt("");
        // setResponseFailTxt(res?.data?.message);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res?.data?.message,
        });
      }
    }
  };
  let options = [];
  {
    districtlist.map((e, i) => options.push({ value: e, label: e }));
  }

  let projectItems = [];
  {
    projectslist.map((e, i) => projectItems.push({ value: e, label: e }));
  }
  // console.log(projectItems);
  // console.log(projects);
  return (
    <Card className="mt-2">
      <Card.Header className="primary-colors">
        {action == "update" ? (
          <FontAwesomeIcon
            icon={faLongArrowAltLeft}
            onClick={() => navigate(-1)}
          />
        ) : (
          ""
        )}{" "}
        &nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faUserPlus} /> New User
      </Card.Header>
      <Card.Body>
        <Form
          className="reg-form"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          {/* <span className="text-danger">{responseFailTxt}</span>
          <span className="text-success">{responseSuccessTxt}</span> */}
          <Row>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Full Name<span className="text-danger">*</span>
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    required
                    id="fname"
                    name="fname"
                    className="input-heigt rounded-0"
                    placeholder="Full Name"
                    value={userdata?.name}
                    {...(userdata.name && { readOnly: true })}
                    onChange={({ target }) => setFullName(target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter full name.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Email address<span className="text-danger">*</span>
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="email"
                    required
                    id="email"
                    name="email"
                    className="input-heigt rounded-0"
                    placeholder="Enter email"
                    value={userdata?.email}
                    {...(userdata.email && { readOnly: true })}
                    onChange={({ target }) => setEmail(target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter email address.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Mobile<span className="text-danger">*</span>
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="tel"
                    required
                    id="mobile"
                    className="input-heigt rounded-0"
                    placeholder="Enter mobile"
                    value={userdata?.mobile}
                    {...(userdata.mobile && { readOnly: true })}
                    onChange={({ target }) => setMobile(target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter mobile number.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              {/* </fieldset> */}
            </Col>
            <Col sm={6}>
              {action == "create" ? (
                <Form.Group className="mb-3">
                  <Form.Label>
                    Password<span className="text-danger">*</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="password"
                      required
                      name="password"
                      className="input-heigt rounded-0"
                      {...(userdata.mobile && { disabled: true })}
                      onChange={({ target }) => setPassword(target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter password.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              ) : (
                ""
              )}{" "}
              <Form.Group className="mb-3">
                <Form.Label>
                  Designation<span className="text-danger">*</span>
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Select
                    className="rounded-0"
                    required
                    name="designation"
                    aria-label="Select designation"
                    onChange={(e) => changeDesignation(e.target.value)}
                  >
                    <option value="">Please select</option>
                    {designationlist.map((e, i) => (
                      <option
                        key={i}
                        value={e?.designation + "," + e?.role}
                        {...(userdata.designation == e?.designation && {
                          selected: true,
                        })}
                      >
                        {e?.designation}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select designation.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Location's(District's)<span className="text-danger">*</span>
                </Form.Label>
                <InputGroup hasValidation>
                  <Select
                    options={options}
                    required
                    isMulti={rc}
                    value={locations}
                    onChange={setSelectedLocations}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Projects<span className="text-danger">*</span>
                </Form.Label>
                <InputGroup hasValidation>
                  <Select
                    value={projects}
                    options={projectItems}
                    required
                    isMulti
                    onChange={setSelectedProjects}
                  />

                  <Form.Control.Feedback type="invalid">
                    Please select projects.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              {/* </fieldset> */}
            </Col>
          </Row>

          <div className="text-center">
            <Button className="primary-colors mt-3" type="submit">
              Create User
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default Register;
